<script setup>
const seoTitle = "Trình Tạo QR Code Miễn Phí, Nhiều Tính Năng Cho Người Việt";
const seoDescription = "Công cụ tạo QR do QRX phát triển dành cho người Việt. Miễn phí tạo mã QR cho link, wifi, địa điểm, Vcar,... có thể tùy chỉnh logo, màu sắc, họa tiết, khung đa dạng.";

useHead({
  title: seoTitle,
  meta: [
    { name: "description", content: seoDescription },
    { name: "og:title", content: seoTitle },
    { name: "og:description", content: seoDescription },
    { name: "keywords", content: "Tạo QRCode" },
  ],
});

useJsonld({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: seoTitle,
});
</script>

<template>
  <section class="qrcode-website max-lg:py-0 max-lg:mx-[-16px]">
    <QrcodeCreateMain />
  </section>
</template>
